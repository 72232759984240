/** 
 * Base file: 
 * /resources/vendor/metronic/theme/assets/global/plugins/simple-line-icons/simple-line-icons.css
 *
 * Look in base file for available icons. Add any new ones required.
 * 
 * Using combined class names instead of single class name with , separated list.
 * 
 * Usage example:
 * <div class="icon-simple-line icon-simple-line-social-facebook"></div>
 * 
 */

/* http://graphicburger.com/simple-line-icons-webfont/ */
@font-face {
    font-family: 'Simple-Line-Icons';

    src:url('/themes/shared/global/plugins/simple-line-icons/fonts/Simple-Line-Icons.eot');
    src:url('/themes/shared/global/plugins/simple-line-icons/fonts/Simple-Line-Icons.eot?#iefix') format('embedded-opentype'),
        url('/themes/shared/global/plugins/simple-line-icons/fonts/Simple-Line-Icons.woff') format('woff'),
        url('/themes/shared/global/plugins/simple-line-icons/fonts/Simple-Line-Icons.ttf') format('truetype'),
        url('/themes/shared/global/plugins/simple-line-icons/fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Simple-Line-Icons';
        src: url('fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
    }
}

.icon {
    font-family: 'Simple-Line-Icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.icon-bell:before {
    content: "\e027";
}

.icon-book-open:before {
    content: "\e04c";
}

.icon-bubble:before {
    content: "\e07d";
}

.icon-calendar:before {
    content: "\e075";
}

.icon-envelope:before {
    content: "\e086";
}

.icon-envelope-open:before {
    content: "\e01e";
}

.icon-home:before {
    content: "\e069";
}

.icon-key:before {
    content: "\e08c";
}

.icon-logout:before {
    content: "\e065";
}

.icon-magnifier:before {
    content: "\e090";
}

.icon-layers:before {
    content: "\e034";
}

.icon-list:before {
    content: "\e067";
}

.icon-paper-clip:before {
    content: "\e093";
}

.icon-plus:before {
    content: "\e095";
}

.icon-puzzle:before {
    content: "\e02d";
}

.icon-rocket:before {
    content: "\e05c";
}

.icon-social-facebook:before {
    content: "\e00b";
}

.icon-social-twitter:before {
    content: "\e009";
}

.icon-user:before {
    content: "\e005";
}